@font-face {
    font-family: "object-sans";
    src: url("../fonts/ObjectSans-Regular.woff2") format("woff2"),
    url("../fonts/ObjectSans-Regular.woff") format("woff");
}

html, body {
    font-family: 'object-sans', 'Neue Montreal', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    min-height: 100%;
}

#root {
    min-height: 100%;
    box-sizing: border-box;
}

a {
    color: #000;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
